﻿let ele = null;
var searchpage = {
  // Functions to be run on each result old (ajax included)
  init: function () {
    $('.searchPage a.next-results-button').on('click keydown', function (e) {
      if (e.type == 'click' || (e.type == 'keydown' && (e.key == 'Enter' || e.key == ' ' || e.key == 'Spacebar'))) {
        var ajaxurl = $(e.target).attr('data-ajax-url');
        $(e.target).addClass('loading');
        e.preventDefault();
        // Retrieve the search result:
        $.ajax({ url: ajaxurl }).done(function (data) {
          // Remove this button:
          $(e.target).remove();

          $('#result-list ul').append($('li', data));
          $('#result-list-inner').append($(data).closest('a.next-results-button'));

          // Run init() so the new button get attached.
          searchpage.init();
          // Set focus on the newest item in searchresults
          setFocusOnNewItem();
        });
      }
    });

    // Check if browser history is enabled
    if (window.history) {
      searchpage.ajaxNavigation();
      if (ele != null) {
        document.getElementById(ele.id).focus();
      }
    }
  },

  // Functions to be run to get ajax navigation enabled
  ajaxNavigation: function () {
    var loadUrl = function (url, sendPushState, isNewsPage = false) {
      ele = document.getElementsByClassName('focusAjax')[0];

      $('#result-list-inner')
        .parent()
        .load(url + ' #result-list-inner', function (response, status, xhr) {
          // Update the header from backend
          if ($('.searchPage').length) {
            var searchResultHeader = $('.searchPage h1', response);
            var numberOfSearchHits = searchResultHeader.text().split('(')[1].split(' ')[0];
            var lang = $('html').attr('lang');
            var searchResultHeaderUpdated = lang === 'en' ? "Search <span class='normal'>(" + numberOfSearchHits + ' hits)</span>' : "Sök <span class='normal'>(" + numberOfSearchHits + ' träffar)</span>';
            $('.searchPage h1').html(searchResultHeaderUpdated);

            // Update search page title dynamically according to number of hits (when using filter buttons)
            var pageTitle = $('head title');
            var pageTitleNumberOfHits = pageTitle.text().split(' ')[0];
            var updatedPageTitle = pageTitle.text().replace(pageTitleNumberOfHits, numberOfSearchHits);
            pageTitle.text(updatedPageTitle);
          }

          // Update the options from backend
          $('.result-controller').html($('.result-controller', response).html());
          $('.block-result-controller').html($('.result-controller', response).html());

          searchpage.init();

          if (sendPushState) {
            history.pushState({ path: url }, '', url);
          }
          if (isNewsPage) {
            setFocusOnNews();
          }
        });
    };

    // Ajax show-more buttons on pages
    $('.calendarPage a.next-results-button, .calendarPage a.show-previous-button, .newsListingPage a.next-results-button').on('click keydown', function (e) {
      if (e.type == 'click' || (e.type == 'keydown' && (e.key == 'Enter' || e.key == ' ' || e.key == 'Spacebar'))) {
        e.preventDefault();
        var url = $(this).attr('href');
        var sendPushState = e.target.attributes['sendPushState'] != null;

        loadUrl(url, sendPushState, true);
      }
    });

    // Ajax filter-buttons on pages, preventDefault is called on onclick so the event work for keyboard, accessibility
    $('.result-controller a').on('click keydown', function (e) {
      if (e.type == 'click' || (e.type == 'keydown' && (e.key == 'Enter' || e.key == ' ' || e.key == 'Spacebar'))) {
        var url = $(this).attr('href');
        var sendPushState = e.target.attributes['sendPushState'] != null;

        loadUrl(url, sendPushState);
      }
    });

    window.onpopstate = function (e) {
      var path = document.location.href.replace(document.location.origin, ''),
        linkElement = $(".searchPage .result-options a[href='" + path + "'], .result-controller a[href='" + path + "']");

      loadUrl(path, false);
    };
  },
};

// handle page reload on radiobutton filter
$(document).on('click keydown', '[open-on-push]', function (e) {
  if (e.type == 'click' || (e.type == 'keydown' && (e.key == 'Enter' || e.key == ' ' || e.key == 'Spacebar'))) {
    var el = $(e.target),
      href = el.attr('data-href');
    document.location = href;
  }
});

$(document).ready(function () {
  if ($('.searchPage, .calendarPage, .newsListingPage, .newsListingPageAsBlock').length > 0) {
    searchpage.init();
  }
});

// Function for setting focus back on filter button, accessibility
function setFocusOnFilterBtn(btnId) {
  document.getElementById(btnId).classList.add('focusAjax');
}

function setFocusOnNews() {
  let elems = document.getElementsByClassName('news-block-link');
  let firstNewElem = elems[elems.length - 5];
  firstNewElem.focus();
}

//Function for setting focus on the first new item when loading more search results, accessibility
function setFocusOnNewItem() {
  let listItems = document.querySelectorAll('[index="1"]');
  listItems[listItems.length - 1].focus();
}
