﻿/*
    Contains event listeners and logic for filters
*/

var uhr = window.uhr || {};

uhr.filters = (function ($) {
  var dropDown = '.dropdown-menu',
    freeTextSearch = '#filter-freetext-input',
    categoryForm = '#filterForm',
    checkedButtonId = '',
    $resultWrapper,
    filterCookie,
    pageId,
    selectedButtonValue = 'button[name="selectedButtonFilter"]';
  var $resultWrapper;

  function init() {
    // Don't try to init if we are on the wrong page
    if (!$('#programList').length) {
      return;
    }

    $resultWrapper = $('#list-result');
    initListeners();

    //Get pageId
    var element = document.getElementById('program-list-page');
    pageId = element.getAttribute('data-page-id');
    filterCookie = 'ProgramListFilterCookie' + pageId;

    //Om id finns bland params så kommer vi inte ladda filter från Cookie, pga Resursbanken
    console.log('DEBUG c', window.Cookies, window.Cookies.get);
    if (pageId && window.Cookies.get(filterCookie)) {
      getFilterFromCookie(window.Cookies.get(filterCookie));
    }
  }

  function initListeners() {
    $(document).on('click', dropDown, stopOutClick);
    $(document).on('click', selectedButtonValue, removeQueryParam);
    $(document).on('click', '.submit-button', handleSubmitButtonClick);
    $(document).on('click', makeFilterOutsideClick);
    $(document).on('change', '#filterForm :input', checkIfChangeInForm);
    $(document).on('click', '.pagenumber', pagningUrlNumber);
    $(document).on('keypress', '#filterForm input[type="checkbox"]', checkWithKeypressed);
    $(document).on('click', '#remove-all-filters', removeParameters);
  }

  function checkIfChangeInForm() {
    $(categoryForm).data('changed', true);
    checkedButtonId = $(categoryForm).attr('id');
  }

  function handleSubmitButtonClick(e) {
    var queryStr = '';

    var $inputChecked = $(dropDown + ' input:checked');
    if ($inputChecked.length > 0) {
      $inputChecked.each(function (i, item) {
        queryStr += (i == 0 ? '?' : '&') + $(item).attr('name') + '=' + $(item).val();
      });
    }
    if ($(freeTextSearch).length !== 0 && $(freeTextSearch).val() !== '') {
      var encodedValue = encodeURIComponent($(freeTextSearch).val());
      queryStr += (queryStr === '' ? '?' : '&') + 'fsq=' + encodedValue;
    }

    window.location.reload();
  }

  function stopOutClick(e) {
    e.stopPropagation();
  }
  function removeParameters() {
    var url = new URL(window.location.href);
    window.Cookies.remove(filterCookie);
    window.location.href = url.origin + url.pathname;
  }

  function removeQueryParam() {
    var url = new URL(window.location.href);
    var searchParams = new URLSearchParams(url.search);
    var filterValue = this.value;
    var values = searchParams.getAll('categoryFilter');

    var filteredValues = values.filter(function (value) {
      return value !== filterValue;
    });

    searchParams.delete('categoryFilter');

    filteredValues.forEach(function (value) {
      searchParams.append('categoryFilter', value);
    });

    var queryString = searchParams.toString();

    var newUrl = url.origin + url.pathname + '?' + queryString;
    if (typeof queryString === 'string' && queryString.length === 0) {
      var inOneHour = 1 / 24;
      window.Cookies.set(filterCookie, '?' + queryString, { expires: inOneHour });
    } else {
      window.Cookies.remove(filterCookie);
    }

    window.location.href = newUrl;
  }

  function makeFilterOutsideClick(e) {
    if ($(categoryForm).data('changed')) {
      // submit the form
      handleSubmitOutsideClick(e);
    }
  }

  function handleSubmitOutsideClick(e) {
    var queryStr = '';
    var $inputChecked = $('input:checked', e.currentTarget);
    if ($inputChecked.length > 0) {
      $inputChecked.each(function (i, item) {
        queryStr += (i == 0 ? '?' : '&') + $(item).attr('name') + '=' + $(item).val();
      });
    }
    if ($(freeTextSearch).length !== 0 && $(freeTextSearch).val() !== '') {
      var encodedValue = encodeURIComponent($(freeTextSearch).val());
      queryStr += (queryStr === '' ? '?' : '&') + 'fsq=' + encodedValue;
    }

    submitAjax(e, queryStr, '#' + checkedButtonId);
  }

  function checkWithKeypressed(event) {
    event.preventDefault();
    var queryStr = '';
    if (event.which === 13) {
      this.checked = !this.checked;
      $(this).trigger('change');
      $(dropDown + ' input:checked').each(function (i, item) {
        queryStr += (i == 0 ? '?' : '&') + $(item).attr('name') + '=';
        queryStr += $(item).val();
      });

      if ($(freeTextSearch).length !== 0 && $(freeTextSearch).val() !== '') {
        var encodedValue = encodeURIComponent($(freeTextSearch).val());
        queryStr += (queryStr === '' ? '?' : '&') + 'fsq=' + encodedValue;
      }

      submitAjax(event, queryStr, '#' + checkedButtonId);
    }
  }

  function pagningUrlNumber(e) {
    var queryStr = '';
    queryStr += '?page=' + $(e.currentTarget).attr('data-pagenumber');

    $(dropDown + ' input:checked').each(function (i, item) {
      queryStr += '&' + $(item).attr('name') + '=';
      queryStr += $(item).val();
    });

    if ($(freeTextSearch).length !== 0 && $(freeTextSearch).val() !== '') {
      var encodedValue = encodeURIComponent($(freeTextSearch).val());
      queryStr += (queryStr === '' ? '?' : '&') + 'fsq=' + encodedValue;
    }

    var focusAnchor = $('#programList').length ? 'post-heading' : 'form0';
    submitAjax(e, queryStr, focusAnchor);
  }

  function submitAjax(e, queryString, changeFocus) {
    e.preventDefault();
    var inOneHour = 1 / 24;
    window.Cookies.set(filterCookie, queryString, { expires: inOneHour });

    if (typeof queryString === 'string' && queryString.length === 0) {
      var url = new URL(window.location.href);
      var newUrl = url.origin + url.pathname;
      window.location.href = newUrl;
    }

    $.ajax({
      url: queryString,
      cache: false,
      type: 'get',
      success: function (data, statusText, jqXHR) {
        replaceResult(data);
        $(categoryForm).data('changed', false);

        // Uppdatera gömt element för sökresultat som skärmläsare ska uppfatta
        $('#sr-result').text($('#post-heading').text());

        if (changeFocus === 'post-heading' || changeFocus === 'form0') {
          document.getElementById(changeFocus).scrollIntoView();
          $('table tbody tr td a:first').focus();
        } else {
          $(changeFocus).focus();
        }
        history.pushState(null, null, queryString);
      },
    });
  }

  function getFilterFromCookie(queryString) {
    $.ajax({
      url: queryString,
      cache: false,
      type: 'get',
      success: function (data, statusText, jqXHR) {
        replaceResult(data);
        setFiltersFromCookie(queryString);
        history.pushState(null, null, queryString);
      },
    });
  }

  function replaceResult(response) {
    $resultWrapper.html(response);
  }

  // Function to check checkboxes, set input text and create filter buttons based on query parameters
  function setFiltersFromCookie(querystring) {
    const categoryFilters = getQueryParam(querystring, 'categoryFilter');
    const freetextValue = getQueryParam(querystring, 'fsq');

    // Check if there are any category filters selected
    if (categoryFilters) {
      const filterValues = categoryFilters.split(',');
      const selectedFiltersDiv = document.querySelector('.selected-filters');
      if (!selectedFiltersDiv) {
        // If selected filters div does not exist, create it
        const selectedFiltersContainer = document.createElement('div');
        selectedFiltersContainer.classList.add('selected-filters');
        selectedFiltersContainer.innerHTML = `<h2>Valda filter</h2><div class="selected-filters-buttons"></div>`;
        document.getElementById('filterForm').insertAdjacentElement('beforeend', selectedFiltersContainer);
      }

      // Generate buttons for each selected category filter if they don't exist
      const selectedFiltersButtons = document.querySelector('.selected-filters-buttons');
      filterValues.forEach((value) => {
        const existingButton = selectedFiltersButtons.querySelector(`button[value="${value}"]`);
        if (!existingButton) {
          const button = createFilterButton(value);
          selectedFiltersButtons.appendChild(button);
        }
      });
    }

    // Always create the "clear all filters" button if it doesn't exist
    const clearFiltersButton = document.getElementById('remove-all-filters');
    if (!clearFiltersButton) {
      const button = document.createElement('button');
      button.type = 'button';
      button.setAttribute('aria-label', 'Rensa filter');
      button.id = 'remove-all-filters';
      button.classList.add('btn', 'btn-default', 'remove-filter-button');
      button.innerHTML = `<svg class="icon icon-kryss" aria-hidden="true" focusable="false"><use xlink:href="/assets/svg/uhriconsprite.svg#icon-kryss"></use></svg> Rensa filter`;
      const selectedFiltersDiv = document.querySelector('.selected-filters');
      if (selectedFiltersDiv) {
        selectedFiltersDiv.querySelector('.selected-filters-buttons').appendChild(button);
      }
    }

    const freetextInput = document.getElementById('filter-freetext-input');
    if (freetextInput && freetextValue) {
      freetextInput.value = freetextValue;
    }
  }

  function getQueryParam(querystring, name) {
    const urlParams = new URLSearchParams(querystring);
    return urlParams.get(name);
  }

  function createFilterButton(value) {
    const filterLabel = document.querySelector(`label[for="filter-${value}"] span`).textContent;
    const button = document.createElement('button');
    button.value = value;
    button.setAttribute('aria-label', `Ta bort filter: ${filterLabel}`);
    button.type = 'button';
    button.name = 'selectedButtonFilter';
    button.classList.add('btn', 'btn-default', 'remove-filter-button');
    button.innerHTML = `<svg class="icon icon-kryss" aria-hidden="true" focusable="false"><use xlink:href="/assets/svg/uhriconsprite.svg#icon-kryss"></use></svg> ${filterLabel}`;
    return button;
  }

  // 'Public'
  return {
    init: init,
  };
})(jQuery);

// Init when DOM is ready
jQuery(uhr.filters.init);
